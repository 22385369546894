import React from 'react';
import AppProvider, { useApp } from './app';
import AlertProvider, { useAlert } from './alert';
import AuthProvider, { useAuth } from './auth';
import NotificationsProvider, { useNotifications } from './notifications';
import SolicitationProvider, { useSolicitation } from './solicitation';
import { useImages, ImageProvider } from './images';

export {
  useAuth,
  useApp,
  useAlert,
  useSolicitation,
  useNotifications,
  useImages,
};

const GlobalContext: React.FC = ({ children }) => {
  return (
    <AppProvider>
      <AlertProvider>
        <AuthProvider>
          <NotificationsProvider>
            <SolicitationProvider>
              <ImageProvider>{children}</ImageProvider>
            </SolicitationProvider>
          </NotificationsProvider>
        </AuthProvider>
      </AlertProvider>
    </AppProvider>
  );
};

export default GlobalContext;
