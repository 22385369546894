import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt-br';
import Routes from './routes';
import reportWebVitals from './utils/reportWebVitals';
import light from './styles/themes/light';
import GlobalStyle from './styles/global';
import GlobalContext, { useApp } from './context';
import ErrorBoundary from './components/ErrorBoundary';

moment.locale('pt-br');
const queryClient = new QueryClient();

const App: React.FC = () => {
  const { online } = useApp();

  useEffect(() => {
    hotjar.initialize(
      Number(process.env.REACT_APP_HOTJAR_ID || ''),
      Number(process.env.REACT_APP_HOTJAR_VERSION || '')
    );

    hotjar.identify('USER_ID', { userProperty: 'value' });
    /*
    // Add an event
    hotjar.event('button-click');

    // Update SPA state
    hotjar.stateChange('/my/page');
    */
  }, []);

  return (
    <>
      <GlobalStyle networkError={!online} />
      <Routes />
    </>
  );
};

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale="pt-br"
        >
          <ThemeProvider theme={light}>
            <GlobalContext>
              <App />
            </GlobalContext>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);

reportWebVitals();
