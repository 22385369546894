import { Notification } from 'entities';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Api from '../../api';
import {
  clearBrowserNotifications,
  closeBrowserNotification,
} from '../../hooks/useBrowserNotifications';
import useStateSync from '../../hooks/useStateSync';
import useWebSocket from '../../hooks/useWebSocket';
import { useAuth } from '../auth';

type NotificationsContextProps = {
  notifications: Notification[];
  setRefId: (id?: string) => void;
  readAllNotifications: () => void;
  deleteAllNotifications: () => void;
  deleteNotification: (id: string) => void;
  subscribe: (onReceive: (notification: Notification) => void) => () => void;
  readNotification: (
    id: string,
    onRead?: ((id: string) => void) | undefined
  ) => void;
};
const NotificationsContext = createContext<NotificationsContextProps>(
  {} as NotificationsContextProps
);

const Notifications: React.FC = ({ children }) => {
  const { user, tokenIsValid } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [refId, setRefId] = useState<string>();

  const [subscriptions] = useStateSync<
    ((notification: Notification) => void)[]
  >([]);

  const unsubscribe = useCallback(
    (index: number) => {
      if (index > -1) subscriptions.current.splice(index, 1);
    },
    [subscriptions]
  );
  const subscribe = useCallback(
    (action: (notification: Notification) => void) => {
      subscriptions.current.push(action);
      return () =>
        unsubscribe(subscriptions.current.findIndex((a) => a === action));
    },
    [subscriptions, unsubscribe]
  );

  const notificationsSort = (a: Notification, b: Notification) =>
    a.readed === b.readed
      ? a.createdAt > b.createdAt
        ? -1
        : 1
      : a.readed
      ? 1
      : -1;

  useEffect(() => {
    if (tokenIsValid)
      Api.Notification.getByRefId(refId || user?.id || '').then(
        (notificationsData) =>
          setNotifications(notificationsData.sort(notificationsSort))
      );
  }, [refId, user?.id, tokenIsValid, setNotifications]);

  const handleReceiveNotification = (notification: Notification) => {
    setNotifications((prev: Notification[]) =>
      [...prev, notification].sort(notificationsSort)
    );
    subscriptions.current.forEach((action) => action(notification));
  };

  useWebSocket<Notification>({
    url: `notification/${refId || user?.id}`,
    onMessage: handleReceiveNotification,
  });

  const makeAsReadNotification = (id: string) => {
    const index = notifications.findIndex((n) => n.id === id);
    if (index === -1) return;
    setNotifications((prev) =>
      Object.assign([], prev, {
        [index]: { ...prev[index], readed: true },
      }).sort(notificationsSort)
    );
  };

  const readNotification = (id: string, onRead?: (id: string) => void) => {
    Api.Notification.makeAsReaded(id).then(() => {
      makeAsReadNotification(id);
      onRead?.(id);
      closeBrowserNotification(id);
    });
  };

  const deleteNotification = (id: string) =>
    Api.Notification.deleteById(id).then(() => {
      setNotifications((prev: any) =>
        prev.filter((n: Notification) => n.id !== id)
      );
      closeBrowserNotification(id);
    });
  const readAllNotifications = () => {
    Api.Notification.makeAllAsReaded(user?.id || '').then(() => {
      setNotifications((prev) => prev.map((n) => ({ ...n, readed: true })));
    });
  };

  const deleteAllNotifications = () => {
    Api.Notification.deleteByRefId(user?.id || '').then(() => {
      setNotifications([]);
      clearBrowserNotifications();
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        setRefId,
        subscribe,
        notifications,
        readNotification,
        deleteNotification,
        readAllNotifications,
        deleteAllNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications: () => NotificationsContextProps = () =>
  useContext(NotificationsContext);

export default Notifications;
