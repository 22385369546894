import React, { forwardRef } from 'react';
import { makeStyles, TextField, BaseTextFieldProps } from '@material-ui/core';
import { useTheme } from 'styled-components';
import InputMask, { ReactInputMask } from 'react-input-mask';

interface InputProps extends BaseTextFieldProps {
  mask?: string;
  value: string;
  InputProps?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = forwardRef<HTMLInputElement | ReactInputMask, InputProps>(
  (props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { id, mask, value, onChange, ...rest } = props;

    const theme = useTheme();
    const useStyles = makeStyles(() => ({
      input: {
        color: theme.colors.primary,
      },
    }));
    const classes = useStyles();

    return (
      <>
        {!mask ? (
          <TextField
            id={id}
            ref={ref as React.RefObject<HTMLInputElement>}
            value={value}
            onChange={onChange}
            InputProps={{
              className: classes.input,
            }}
            {...rest}
          />
        ) : (
          <InputMask
            ref={ref as React.RefObject<ReactInputMask>}
            id={id}
            mask={mask}
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                InputProps={{
                  className: classes.input,
                }}
                {...rest}
              />
            )}
          </InputMask>
        )}
      </>
    );
  }
);

export default Input;
